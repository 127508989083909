import 'bootstrap-datepicker'
import 'eonasdan-bootstrap-datetimepicker'
import { OBSERVER } from '../formbuilder'
import { AddressGoogle } from './addressGoogle'
import { isMobile } from './helper'

// Le package à appliquer pour les formulaires
export function formsPackage(classeContainerFormBuilder) {
  inputsAndTextareaLabel(classeContainerFormBuilder)
  textareaHeight(classeContainerFormBuilder)
  tailSelect(classeContainerFormBuilder)
  timepicker(classeContainerFormBuilder)
  calendarInput(classeContainerFormBuilder)
  addressAutocomplete(classeContainerFormBuilder)
}

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel(classeContainerFormBuilder) {
  const elementsString = classeContainerFormBuilder + ' input[type="text"],' + classeContainerFormBuilder + ' input[type="tel"],' + classeContainerFormBuilder + ' input[type="email"],' + classeContainerFormBuilder + ' textarea',
    formElements = document.querySelectorAll(elementsString),
    formElementsLength = formElements.length
  let i

  for (i = 0; i < formElementsLength; i++)
    formElements[i].parentNode.parentNode.dataset[formElements[i].tagName.toLowerCase() + 'value'] = formElements[i].value

  const setValue = e => e.target.parentNode.parentNode.dataset[e.target.tagName.toLowerCase() + 'value'] = e.target.value

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue
  })

  OBSERVER.on('inputsAndTextareaLabel')
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight(classeContainerFormBuilder) {
  const onInput = e => {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`
  }

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: classeContainerFormBuilder + ' textarea',
    function: onInput
  })

  OBSERVER.on('textareaHeight')
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect(classeContainerFormBuilder) {

  let pathImagesIcons = '/plugins/blanko/forms/assets/images/icons/'

  if (!isMobile()) {
    tail.select(classeContainerFormBuilder + ' select', {animate: false})
  }

  // Ajouter les images dans les options du Tail Select
  let selectBoxes, selectBoxesLength, i, j
  selectBoxes = document.querySelectorAll(classeContainerFormBuilder + ' .tail-select')
  selectBoxesLength = selectBoxes.length

  for (i = 0; i < selectBoxesLength; i++) { //Looper dans chaques boites de sélections Tail Select
    let tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option')
    let nativeSelect = selectBoxes[i].previousElementSibling
    let nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)')

    // Ajouter l'icone en symbole
    let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
      useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', pathImagesIcons + 'symbols.svg#ico-pointer')
    svgElem.appendChild(useElem)
    selectBoxes[i].appendChild(svgElem)

    for (j = 0; j < nativeSelectItems.length; j++) { //Looper dans chaques item du Tail Select
      let imgPath = nativeSelectItems[j].dataset.image
      if ((typeof imgPath !== 'undefined') && imgPath != '') {
        let newImage = document.createElement('img')
        newImage.src = imgPath
        tailSelectItems[j].classList.add('has-image')
        tailSelectItems[j].appendChild(newImage)
      }
    }
  }
}

// Initialisation du champ de timepicker
export function timepicker(classeContainerFormBuilder) {
  if (!isMobile()) {
    $(classeContainerFormBuilder +' .form-control.timepicker').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down'
      },
    })
  }
}

// Initialisation du champ de calendrier
export function calendarInput(classeContainerFormBuilder) {

  // Si on est pas en mobile, mettre le calendrier en JS
  if (!isMobile()) {

    $.fn.datepicker.dates['fr'] = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'jui', 'août', 'sep', 'oct', 'nov', 'déc'],
      today: 'Aujourd\'hui',
      clear: 'Clear',
      format: 'dd/mm/yyyy',
      titleFormat: 'MM yyyy',
      weekStart: 0
    }

    $(classeContainerFormBuilder + ' .datepickerWrapper').datepicker({
      language: 'fr',
      format: 'dd MM yyyy',
      todayHighlight: true,
      startDate: $(classeContainerFormBuilder + ' .datepickerWrapper').data('startDate')
    })

    $(classeContainerFormBuilder + ' .input-calendar input').on('focus', function () {
      $(this).parent().parent().find('.datepickerWrapper').addClass('show')
    })

    $(classeContainerFormBuilder + ' .datepickerWrapper').on('changeDate', function () {
      $(this).prev().find('input').val($(this).datepicker('getFormattedDate'))
      $(this).removeClass('show')
    })

    const closeCalendar = () => {
      let i, x = document.querySelectorAll(classeContainerFormBuilder + ' .datepickerWrapper')

      for (i = 0; i < x.length; i++)
        x[i].classList.remove('show')
    }

    const preventClose = e => e.stopPropagation()

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      function: closeCalendar
    })

    OBSERVER.add({
      name: 'input-calendar',
      event: 'click',
      target: classeContainerFormBuilder + ' .input-calendar',
      function: preventClose
    })

    OBSERVER.on('input-calendar')

    // Si on est en mobile, mettre utiliser les calendriers en HTML5
  } else {
    $(classeContainerFormBuilder + ' .input-calendar input').attr('type', 'date')
  }
}

// Google Autocomplete pour le champ Adresse
function addressAutocomplete(classeContainerFormBuilder) {
  new AddressGoogle(classeContainerFormBuilder)
}

